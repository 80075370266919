import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getPartnersList } from '../../utils/forms/API/requests';

const PartnerContainer = styled.main`
  text-align: center;
  div {
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }
`;

const PartnerLayout = ({ partnerType, children }) => {
  const [partners, setPartners] = useState('');
  useEffect(() => {
    const getPartners = async () => {
      const cb = async res => {
        const partnerMap = array => {
          return array.map((el, i) => <p key={i}>{el}</p>);
        };
        if (res !== null) {
          setPartners(partnerMap(res));
        } else {
          try {
            const response = await fetch(
              'https://s3.us-west-1.amazonaws.com/offers.printfingertech.net/js/network.json'
            );
            if (response) {
              const resJson = await response.json();
              setPartners(partnerMap(resJson));
            } else {
              setPartners(
                'There was an error retrieving the partner list.  Please refresh the page and try again.'
              );
            }
          } catch (error) {
            setPartners(
              'There was an error retrieving the partner list.  Please refresh the page and try again.'
            );
          }
        }
      };
      getPartnersList(partnerType, cb);
    };
    getPartners();
  }, [partnerType]);

  return (
    <PartnerContainer>
      <div>{children}</div>
      {partners}
    </PartnerContainer>
  );
};

PartnerLayout.propTypes = {
  children: PropTypes.node,
  partnerType: PropTypes.string,
};

export default PartnerLayout;
