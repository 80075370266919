import React from 'react';
import PartnerLayout from '../components/Containers/PartnerLayout';

const GeneralPartners = () => {
  return (
    <PartnerLayout partnerType="mortgage">
      <h4>Our network includes:</h4>
    </PartnerLayout>
  );
};

export default GeneralPartners;
